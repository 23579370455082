import React, { useEffect, useState } from "react";
import "./Logout.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useCookies } from "react-cookie";
import { getDomain } from "../utils/web-utils";

const Logout = () => {
	const { logout } = useAuth0();
	const [counter, setCounter] = useState(10);
	const [cookie, setCookie] = useCookies([]);
	let domain = getDomain();

	useEffect(() => {
		setTimeout(() => {
			console.log("log out initialized!!!");
			setCookie("ACCESS_TOKEN", "", { path: "/", domain });
			logout({ logoutParams: { returnTo: window.location.origin } });
		}, 10000);
		setInterval(() => {
			setCounter((prev) => (prev -= 1));
		}, 1000);
	}, []);

	return (
		<div className="bgText">
			Your session has been expired Authentication required !!! <br />
			Redirecting to login page in &nbsp;{counter}&nbsp;secs...
			<br />
			{console.log(`logging out in ${counter} seconds`)}
			<a
				className="und"
				target="_top"
				onClick={() => {
					logout({ logoutParams: { returnTo: window.location.origin } });
				}}
			>
				Click Here to Login Again
			</a>
		</div>
	);
};

export default Logout;
