import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { clarissaScopes } from "../config/index";
import { isResolutionInvalid, getDomain } from "../utils/web-utils";
import { useCookies } from "react-cookie";
import { getUserStreams } from "../utils/data";

const useAppInit = () => {
	const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
	const [, setCookie] = useCookies([]);
	const [menuData, setMenuData] = useState([]);
	const [userId, setUserId] = useState("");
	const [isLoading,  setIsLoading] = useState(true);

	useEffect(() => {
		user && setUserId(user["https://user.synamedia.com/id"] || "");
	}, [user]);

	function createCookie(domain, key, value) {
		setCookie(key, value, {
			path: "/",
			domain,
		});
	}

	async function getAuth0AccessToken() {
		const domain = getDomain();
		const accessToken = await getAccessTokenSilently({
			scope: `${clarissaScopes}`,
		});
		accessToken && createCookie(domain, "ACCESS_TOKEN", accessToken);
		return accessToken && accessToken;
	}

	const initApp = async (auth) => {
		// const auth = await getAuth0AccessToken();
		const [data] = auth && (await Promise.allSettled([getUserStreams(auth)]));
		data && setMenuData(data?.value);
		setIsLoading(false);
	};

	useEffect(() => {
		setIsLoading(true);
		(async () => {
			const auth = await getAuth0AccessToken();
			auth && await initApp(auth);
		})();
	}, []);

	return [isResolutionInvalid(), menuData, userId, isLoading];
};

export default useAppInit;
