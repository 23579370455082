import { getApiGatewayUrl } from "../config";
import axios from "axios";
import { getCookie } from "./web-utils";

export const getUserStreams = async (auth) => {
	const HOSTNAME = getApiGatewayUrl();
	try {
		const url = `${HOSTNAME}/ui/streams`;
		const streamData = await axios.get(url, {
			headers: { Authorization: `Bearer ${auth}` },
		});
		const initialData = streamData?.data;
		let apps = [];
		let parsedData = [];
		initialData?.streams?.forEach(
			(stream) => (apps = [...apps, ...stream?.apps])
		);
		const apiCalls = [];
		apps.forEach((app) => {
			const menuUrl = `${HOSTNAME}/ui/app/${app?.appId}/menu`;
			apiCalls.push(
				axios
					.get(menuUrl, {
						headers: { Authorization: `Bearer ${auth}` },
					})
					.then((response) => response?.data)
			);
		});
		const responses = await Promise.all(apiCalls);
		if (responses) {
			parsedData = initialData.streams.map((stream) => {
				stream.apps = stream.apps.map((app) => {
					const menuItem = responses.find(
						(response) => app.appId === response?.app?.appId
					);
					if (menuItem) {
						return { ...app, menu: menuItem?.menu };
					}
				});
				return stream;
			});
		}
		return parsedData;
	} catch (error) {
		console.error(error, "Error While getting user data");
		return [];
	}
};

export const getDashboardLink = async (dashboardId) => {
	const HOSTNAME = getApiGatewayUrl();
	try {
		const url = `${HOSTNAME}/ui/dashboard/${dashboardId}`;
		const response = await axios.get(url, {
			headers: { Authorization: `Bearer ${getCookie("ACCESS_TOKEN")}` },
		});
		return response && response?.data;
	} catch (error) {
		console.error(error, "Error While getting app Menu data");
	}
};
