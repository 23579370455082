import React, { useState } from "react";
import AppLayout from "./components/AppLayout";
import { Loader } from "@ip-synamedia/common-ui-library";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { getCookie } from "./utils/web-utils";
const App = () => {
	const { isLoading, isAuthenticated } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		async function authenticateWithRedirect() {
			if (!isLoading && !isAuthenticated) {
				console.info("User is not authenticated..");
				navigate("/login");
			}
		}
		authenticateWithRedirect();
	}, [isLoading, isAuthenticated]);

	if (isLoading || !isAuthenticated) {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Loader type="Rings" />
			</div>
		);
	}

	return isAuthenticated && <AppLayout />;
};

export default App;
