import reach from "../assets/Reach.png";
import summary from "../assets/Summary.png";
import trends from "../assets/Trends.png";
import con from "../assets/Content.svg";
import value from "../assets/Value.png";
import features from "../assets/Features.png";
import screens from "../assets/Screens.png";
import operational from "../assets/Operational.svg";
import journeys from "../assets/Journeys.png";
import audience from "../assets/Audience.svg";
import errors from "../assets/Errors.png";
export const ImageMap = {
	reach: reach,
	summary: summary,
	trends: trends,
	contentInsights: con,
	value: value,
	features: features,
	screens: screens,
	operational: operational,
	journeys: journeys,
	audience: audience,
	errors: errors
};

export const SortByOptions = [
	{ id: "name", label: "Name" },
	// { id: "description", label: "Description" },
];