import { Card } from "@ip-synamedia/common-ui-library";
import { ImageMap } from "../utils/constants";
import { getDashboardLink } from "../utils/data";

const CardView = (props) => {
	const { data, setSelectedPage } = props;

	const handleOnClick = async (dashboardId, name) => {
		const LINK = await getDashboardLink(dashboardId);
		LINK && setSelectedPage({ src: LINK?.embedUrl, name: name });
		const toggleButton = document?.querySelector(
			"[data-testid='sideMenuCollapse']"
		);
		toggleButton.getAttribute("collapse") === "false" && toggleButton.click();
		// LINK && window.open(LINK.embedUrl, "_blank");
	};

	return (
		<>
			<div className="apps">
				{data?.map((page) => {
					return (
						<Card
							key={page?.name}
							id={page.name}
							type="clickable"
							data-testid="Card"
							onClick={() => handleOnClick(page?.dashboard, page?.name)}
							title={
								<>
									<div
										data-testid="CardImage"
										className={
											page.name?.toLowerCase()?.includes("summary") ||
											page.name?.toLowerCase()?.includes("content")
												? `imageContainer  background1`
												: `imageContainer  background2`
										}
									>
										<img
											src={ImageMap[page?.name?.toLowerCase()]}
											className="appImage"
										/>
									</div>
									<div className="border">
										<span className="bottomBorder" />
									</div>
									<div className="appName">{page.name}</div>
								</>
							}
						/>
					);
				})}
			</div>
		</>
	);
};
export default CardView;
