import React from "react";
import { useState, useEffect } from "react";
import "./AppLayout.css";
import Navbar from "./Navbar";
import SideBar from "./Sidebar";
import {
	ApplicationsIcon,
	Loader,
	Toast,
} from "@ip-synamedia/common-ui-library";
import Content from "./Content";
import useAppInit from "../init/useAppInit";

const AppLayout = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isResolutionInvalid, menuData, userId, isLoading] = useAppInit();

	//To format response Data into Side menu format
	const parseRawData = (jsonData) => {
		const StreamLabel = jsonData.length > 1 ? "Streams" : "Stream";
		let parsedData = [
			{ id: "content", label: StreamLabel, type: "section_header", items: [] },
		];
		jsonData.forEach((stream) => {
			parsedData[0].items.push({
				id: stream?.id,
				type: "sub_menu",
				label: stream.name,
				icon: <ApplicationsIcon />,
				apps: stream?.apps,
				items: stream?.apps?.map((app) => {
					return {
						id: app?.appId,
						label: app?.name,
					};
				}),
			});
		});
		return parsedData;
	};

	// Get Selected APP Data  from Raw  Data
	const getSelectedAppDate = (rawData, selectedItem) => {
		const stream = rawData?.filter((stream) =>
			stream?.apps?.some((app) => app.appId === selectedItem)
		);
		return stream[0]?.apps?.find((app) => app?.appId === selectedItem);
	};

	const MenuData = parseRawData(menuData);

	const [selectedItem, setSelectedItem] = useState(menuData[0]?.apps[0]?.appId);
	const [selectedPage, setSelectedPage] = useState({ src: null, name: null });

	useEffect(() => {
		setSelectedItem(menuData[0]?.apps[0]?.appId);
	}, [menuData]);

	const selectedApp = getSelectedAppDate(menuData, selectedItem);

	return (
		<>
			<Navbar userId={userId} />
			<main className="main">
				{isLoading ? (
					<>
						<Loader />
					</>
				) : (
					<div className="contentBackground">
						{
							<SideBar
								isLoading={isLoading}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
								menuItems={MenuData}
								selectedItem={selectedItem}
								setSelectedItem={(event, id) => {
									setIsOpen(false);
									setSelectedItem(id);
									setSelectedPage({ src: null, name: null });
								}}
							/>
						}
						<Content
							label={selectedApp?.name}
							data={selectedApp?.menu?.pages || []}
							selectedPage={selectedPage}
							setSelectedPage={setSelectedPage}
						/>
					</div>
				)}
			</main>
			{isResolutionInvalid && (
				<Toast
					description="This portal is best viewed at 1920x1080 resolution."
					position="bottomCenter"
					variant="warning"
					duration={1500}
				/>
			)}
		</>
	);
};

export default AppLayout;
