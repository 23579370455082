export function getDomain() {
	const url = new URL(window.location.origin);
	const domain = url.hostname;
	return domain;
}

export function isResolutionInvalid() {
	if (window.screen.availHeight !== 1080 || window.screen.availWidth !== 1920)
		return true;
	else return false;
}

export const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(";").shift();
};
