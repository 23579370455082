import {
	ApplicationsIcon,
	ChevronLeftIcon,
	BreadCrumbs,
	Button,
	CloseIcon,
	Dropdown,
	Link,
	SortUpDownIcon,
} from "@ip-synamedia/common-ui-library";
import { SortByOptions } from "../utils/constants";
import { ReactComponent as Menu } from "../assets/menu.svg";

const Filter = (props) => {
	const {
		filterState,
		onFilterChange,
		label,
		backButtonEnabled,
		handleBackButtonClick,
		selectedPage,
	} = props;

	return (
		<>
			{!selectedPage?.name ? (
				<>
					<div className="contentTitle">
						<div className="label">
							{backButtonEnabled && (
								<ChevronLeftIcon onClick={(e) => handleBackButtonClick(e)} />
							)}
							{label}
						</div>
						{/* <div className="filtersContainer">
							<div className="sortButtons">
								<Button
									color="tertiary"
									data-testid="button1"
									disabled={backButtonEnabled}
									onClick={() => {
										onFilterChange((prevState) => ({
											...prevState,
											dir: prevState?.dir === "asc" ? "desc" : "asc",
										}));
									}}
								>
									<SortUpDownIcon />
								</Button>
								<Dropdown
									disabled={backButtonEnabled}
									value={filterState?.sortBy}
									options={SortByOptions}
									onValueChange={(value) => {
										onFilterChange((prevState) => {
											return { ...prevState, sortBy: value };
										});
									}}
								/>
							</div>
							<div className="toggleViewButtons">
								<Button
									color="tertiary"
									data-testid="button1"
									onClick={() => {
										onFilterChange((prevState) => ({
											...prevState,
											view: "card",
										}));
									}}
									disabled={backButtonEnabled}
								>
									<ApplicationsIcon />
								</Button>
								<Button
									color="tertiary"
									data-testid="button2"
									onClick={() => {
										onFilterChange((prevState) => ({
											...prevState,
											view: "stack",
										}));
									}}
									disabled={backButtonEnabled}
								>
									<Menu />
								</Button>
							</div>
						</div> */}
					</div>
					<div className="bottomBorder padding-2" />
				</>
			) : (
				<div className="navigationItems">
					{backButtonEnabled && (
						<ChevronLeftIcon onClick={(e) => handleBackButtonClick(e)} />
					)}
					<BreadCrumbs>
						<Link onClick={(e) => handleBackButtonClick(e)}>{label}</Link>
						<Link>{selectedPage?.name}</Link>
					</BreadCrumbs>
					{/* <CloseIcon onClick={(e) => handleBackButtonClick(e)} /> */}
				</div>
			)}
		</>
	);
};

export default Filter;
