import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { useCookies } from "react-cookie";
import {
	getAuth0Domain,
	getAuth0ClientId,
	getAuth0ProjectIdUrl,
	setConfig,
	clarissaScopes,
} from "../config";
import { Loader } from "@ip-synamedia/common-ui-library";
import { getDomain } from "../utils/web-utils";

function Auth0ProviderWithHistory({ children }) {
	// Ensure initialisation of the config via dynamic loading of the config.json
	const [cookie, setCookie] = useCookies([]);
	const [configLoaded, setConfigLoaded] = useState(false);
	const [orgIdLoaded, setOrgIdLoaded] = useState(false);
	const [orgId, setOrgId] = useState();
	const [isProjectIdMissing, setIsProjectIdMissing] = useState(false);
	// for dev pass project id Here "o30s10ll"
	let projectId;
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	projectId = searchParams.get("projectId");

	const audience = "https://projects.synamedia.com";

	const loadConfigs = async () => {
		let domain = getDomain();
		if (projectId) {
			// if projectId is present in query parameter then store in cookie
			setCookie("projectId", projectId, { path: "/", domain });
		} else {
			// else check if cookie have projectId and store in local variable
			if (cookie.projectId) {
				projectId = cookie.projectId;
			} else {
				//else if project id is not in cookie and query parameter, give missing project id error
				setIsProjectIdMissing(true);
			}
		}

		// load configurations from config.json and store in config variable
		fetch("/config.json")
			.then((response) => response.json())
			.then((configValue) => {
				const isConfigUpdated = setConfig(configValue);
				setConfigLoaded(isConfigUpdated);
				// fetch organization id based on projectId
				const getOrgByProjectIdUrl = getAuth0ProjectIdUrl();
				fetch(getOrgByProjectIdUrl + projectId)
					.then((response) => response.text())
					.then((orgId) => {
						setOrgId(orgId);
						setOrgIdLoaded(true);
					});
			})
			.catch(console.error);
	};

	useEffect(() => {
		loadConfigs();
	}, []);

	const domain = getAuth0Domain();
	const clientId = getAuth0ClientId();

	const onRedirectCallback = (appState) => {
		window.history.replaceState(
			{},
			document.title,
			appState?.returnTo || window.location.pathname
		);
	};

	if (isProjectIdMissing) return <>ProjectId is Missing!</>;

	return configLoaded && orgIdLoaded ? (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			// useRefreshTokens={true}
			authorizationParams={{
				redirect_uri: window.location.origin,
				organization: orgId,
				audience: audience,
				onRedirectCallback: onRedirectCallback,
				scope: clarissaScopes,
			}}
		>
			{children}
		</Auth0Provider>
	) : (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				paddingTop: "300px",
			}}
		>
			{" "}
			<Loader type="Rings" />
		</div>
	);
}

export default Auth0ProviderWithHistory;
