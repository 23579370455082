import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { clarissaScopes } from "../config/index";

function Login() {
	const { loginWithRedirect } = useAuth0();
	const auth0Login = () => {
		loginWithRedirect({
			scope: `${clarissaScopes}`,
		});
	};
	useEffect(() => {
		auth0Login();
	});

	return <></>;
}

export default Login;
