import { Loader, SideMenu } from "@ip-synamedia/common-ui-library";

const SideBar = (props) => {
	const {
		isLoading,
		isOpen,
		setIsOpen,
		menuItems,
		selectedItem,
		setSelectedItem,
	} = props;

	return (
		<>
			{isLoading ? (
				<aside>
					<Loader />
				</aside>
			) : menuItems[0].items?.length === 0 && !isLoading &&  !selectedItem ? (
				<aside className="errorSideMenu">Failed To Load Stream Data</aside>
			) : (
				selectedItem && (
					<SideMenu
						id="clarissa_sideMenu"
						menuData={menuItems}
						onCollapseChange={(event, state) => setIsOpen(state)}
						onSelectionChange={setSelectedItem}
						selectedId={selectedItem}
					/>
				)
			)}
		</>
	);
};
export default SideBar;
