import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/Logo.svg";
import {
	SearchIcon,
	Dropdown,
	AccountIcon,
	UserIcon,
	LogoutIcon,
} from "@ip-synamedia/common-ui-library";
import { ReactComponent as Clarissa } from "../assets/Clarissa.svg";
const Navbar = (props) => {
	const { userId = "userName" } = props;
	const { logout } = useAuth0();
	// const [showSearch, setShowSearch] = useState(false);

	const handleLogOut = () => {
		logout({ logoutParams: { returnTo: window.location.origin } });
	};

	const handleMenuClick = (value) => {
		if (value === "signOut") {
			handleLogOut();
		}
	};
	return (
		<nav className="nav">
			<div className="imageTag">
				<img className={"logo"} src={logo} height={"32px"} />
			</div>
			<div className="prodTitle">
				<Clarissa />
				Clarissa
			</div>
			{/* <div className="searchIcon" onClick={() => setShowSearch()}>
				{showSearch ? (
					<></>
				) : (
					<SearchIcon fill="#00000" height={"20px"} width={"20px"} />
				)}
			</div> */}
			<Dropdown
				align="end"
				onOpenChange={function noRefCheck() {}}
				onValueChange={(value) => handleMenuClick(value)}
				headerComponent={true}
				options={[
					{
						icon: <UserIcon fill="inherit" height="14px" width="14px" />,
						id: "email",
						label: userId,
					},
					{
						icon: <LogoutIcon fill="inherit" height="14px" width="14px" />,
						id: "signOut",
						label: "Sign Out",
					},
				]}
				type="menu"
				value=""
			>
				<AccountIcon height="20px" width="20px" />
			</Dropdown>
		</nav>
	);
};

export default Navbar;
