import { useEffect, useState } from "react";
import Filter from "./Filter";
import CardView from "./CardView";
import StackView from "./StackView";
import { EmptyState } from "@ip-synamedia/common-ui-library";
import { ReactComponent as NoData } from "../assets/Nodata.svg";
const Content = (props) => {
	const { label, data, selectedPage, setSelectedPage } = props;
	const [filterState, setFilterState] = useState({
		dir: "asc",
		sortBy: "name",
		view: "card",
	});

	const [filteredData, setFilteredData] = useState(data);

	const filterData = (data) => {
		const new_data =
			data &&
			data?.sort((a, b) => {
				return filterState.dir === "desc"
					? b[filterState?.sortBy]?.localeCompare(a[filterState?.sortBy])
					: a[filterState?.sortBy]?.localeCompare(b[filterState?.sortBy]);
			});
		setFilteredData(new_data);
	};

	// To Apply filters on Initial render
	useEffect(() => {
		data && filterData(data);
	}, []);

	// To Apply filters once ay Filter change is detected
	useEffect(() => {
		data && filterData(data);
	}, [filterState, data]);

	// To Navigate back to main menu once stream is changed
	useEffect(() => {
		data && setSelectedPage({ src: null, name: null });
	}, [data]);

	const handleBackButtonClick = () => {
		const toggleButton = document?.querySelector(
			"[data-testid='sideMenuCollapse']"
		);
		toggleButton.getAttribute("collapse") === "true" && toggleButton.click();
		setSelectedPage({ src: null, name: null });
	};
	return (
		<div
			className="contentContainer"
			style={{
				paddingTop: selectedPage?.name ? "0px" : "20px",
				gap: selectedPage?.name ? "0" : "1rem",
			}}
		>
			<Filter
				label={label}
				filterState={filterState}
				onFilterChange={setFilterState}
				handleBackButtonClick={() => handleBackButtonClick()}
				backButtonEnabled={!!selectedPage.name}
				selectedPage={selectedPage}
			/>
			{!selectedPage?.src ? (
				<section
					className="content"
					style={data.length === 0 ? { justifyContent: "center" } : {}}
				>
					{data?.length !== 0 && filterData?.length !== 0 ? (
						<>
							{filterState?.view === "card" ? (
								<>
									<CardView
										data={filteredData}
										setSelectedPage={setSelectedPage}
									/>
								</>
							) : (
								<>
									<StackView
										data={filteredData}
										setSelectedPage={setSelectedPage}
									/>
								</>
							)}
						</>
					) : (
						<>
							<EmptyState
								image={<NoData />}
								showImage={true}
								showText={true}
								description={"No Page Data"}
							/>
						</>
					)}
				</section>
			) : (
				<section className="embeddedSection">
					<iframe className={"embeddedURL"} src={selectedPage?.src}></iframe>
				</section>
			)}
		</div>
	);
};

export default Content;
