let config;

const set = (newConfig) => {
	config = newConfig;
	return true;
};

const getAuth0Domain = () => config && config.auth0Domain;
const getAuth0ClientId = () => config && config.auth0ClientId;
const getAuth0ProjectIdUrl = () => config && config.auth0ProjectIdUrl;
const getApiGatewayUrl = () => config && config.apiGatewayUrl;
const clarissaScopes = "clarissa:dashboards:view";

export {
	set as setConfig,
	getAuth0Domain,
	getAuth0ClientId,
	getAuth0ProjectIdUrl,
	getApiGatewayUrl,
	clarissaScopes,
};
